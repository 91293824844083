import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import Arrow from "../components/Arrow"
import PageLinkFade from "../components/PageTransitionFade"
import Footer from "../components/footer"
import Header from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default class Experiences extends React.Component {
    componentDidMount() {
        const body = document.querySelector("body")
        body.style.overflow = "unset"
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query {
                        allEvents: allDatoCmsEvent {
                            nodes {
                                id
                                slug
                                date
                                title
                                hidePage
                                introTitle
                                introParagraph
                                heroImage {
                                    gatsbyImageData
                                }
                                meta {
                                    createdAt(formatString: "MMM, YYYY")
                                }
                            }
                        }
                    }
                `}
                render={({ allEvents }) => (
                    <Layout>
                        <Seo title='Experiences' />
                        <Header className='black w-b' />
                        <div data-scroll-container id='scroll-container' className='data-scroll-container'>
                            <div className='h-50' />
                            <div className='h-30vh back-white flex text-center'>
                                <h1 className='w-100 mta mba'>Experiences</h1>
                            </div>
                            <div>
                                {allEvents.nodes.map(event => {
                                    if (event.hidePage) return null
                                    return <Event key={event.id} {...event} />
                                })}
                            </div>
                            <div className='back-white '>
                                <Footer />
                            </div>
                        </div>
                    </Layout>
                )}
            />
        )
    }
}

export const Event = ({ title, slug, date, introTitle, introParagraph, heroImage, meta }) => {
    const [displayedText, setDisplayedText] = React.useState(introParagraph.slice(0, 350).trim() + "...")
    const showFullText = () => setDisplayedText(introParagraph)
    return (
        <div className={`relative bt1 w-100-p-b flex-c flex`}>
            <div className={`back-white flex  w-100-p-b  relative flex-r w-100-p  h-100-p flex-grow-1 ma-h-100vh-50  r-c-l `}>
                <div className={`w-50-p  p-40 flex flex-r  w-100-p-b  `}>
                    <div className='flex flex-c justify-between'>
                        <h4>
                            {title} {date && `| ${date}`}
                        </h4>
                        <div>
                            <h2 className='m-t-110 m-w-275'>{introTitle}</h2>
                            <p className='body-text m-w-490 m-t-30 mb20 m-hide'>
                                {displayedText}
                                {displayedText.length <= 353 && (
                                    <button className='read-more grey ' onClick={showFullText}>
                                        Read More
                                    </button>
                                )}
                            </p>
                            <p className='body-text m-w-490 m-t-30 mb20 m-show'>{introParagraph}</p>
                            <PageLinkFade to={`/event/${slug}`}>
                                <span className='flex align-center'>
                                    <Arrow className='f-2A2A2A r-b' />
                                    <div className='p-l-10  button c-2A2A2A'>I'm Interested</div>
                                </span>
                            </PageLinkFade>
                            <div className='m-show pos-rel ratio-16-9 mt40'>
                                <GatsbyImage image={heroImage?.gatsbyImageData} class='bg-image' alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`w-50-p p-40 b-l-s-50 d-n-b w-100-p-b  `}>
                    <div className='pos-rel ratio-16-9'>
                        <GatsbyImage image={heroImage?.gatsbyImageData} class='bg-image' alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}
